import bus from '@/utils/bus.js'
import { DEV_MODE } from './constants.js'
import md5 from 'blueimp-md5'
import api from '../api'
export const wsAppKey = "5f88825c28c56763eb0d8b270646e4b1"

export const wsAppSecret = "a2a906fe997867135886e5c4f62b03d2"

const protocol = /^https/.test(location.href) ? 'wss' : 'wss'

export const wsURL = DEV_MODE ? `${protocol}://dev-ws.360ba.live` : `${protocol}://ws.360ba.co`

class Connect {

	constructor(uid) {
		this.uid = uid
		this.init()
	}

	uid = null
	presence_uid = null
	socket_id = null
	instance = null
	timer = null
	tryCount = 1000
	heartTimer = null
	force = false

	async init() {
		const ws = this
		// this.force = false
		if (this.instance) {
			this.uid && this.subscribe(uid)
		} else {
			const webclienttype = 'web'
			const t = await api.post('/wt', { webclienttype })

			if (t && t.code == 200) {
				const url = `${wsURL}?webclienttype=${webclienttype}&token=${t.data.t}&szc=1709371176`
				const instance = new WebSocket(url);
				instance.onopen = (event) => {
					console.info('ws.onopen', event, ws)
					ws.tryCount = 999999
					ws.socket_id = null
					ws.instance = instance
					ws.heartbeat()
					ws.force = false
					bus.$emit('ws.open', event)
				};
				instance.onerror = (event) => {
					bus.$emit('ws.error', event)
					console.warn("ws.error", event);
				};
				instance.onclose = (event) => {
					console.info("ws.close", event);
					ws.instance = null;
					ws.timer && clearTimeout(ws.timer);
					ws.heartTimer && clearTimeout(ws.heartTimer)
					if (ws.force || event.code == 10000) {
						console.info("ws.reconnect");
					} else if (--ws.tryCount > 0) {
						ws.timer = setTimeout(() => {
							ws.init()
						}, 3000);
					} else {
						// location.reload()
					}
				};
				instance.onmessage = (event) => {
					try {
						const data = typeof event.data == 'string' ? JSON.parse(event.data) : event.data;
						data.data = typeof event.data == 'string' ? JSON.parse(data.data) : data.data
						// console.info('ws.message', data)
						bus.$emit('ws.message', data)
						if (data.event == 'woker:connection_established') {
							ws.socket_id = data.data.socket_id
							ws.uid && ws.subscribe(ws.uid)
						}
					} catch (e) {
						console.warn('ws.event.parse.error', e)
					}
				};
			}

		}
	}

	reInit() {
		if (this.instance) {
			try {
				// bus.$off('ws.open')
				// bus.$off('ws.message')
				// bus.$off('ws.error')
				this.timer && clearTimeout(this.timer)
				this.heartTimer && clearTimeout(this.heartTimer)
				this.instance.close()
				this.instance = null
			} catch (e) {
				//TODO handle the exception
			}
		}
		this.force = true
		this.init()
	}

	send(data) {
		if (this.instance && this.instance.readyState == 1) {
			// console.info('ws.send', data)
			this.instance.send(JSON.stringify(data))
			return true
		}
		return false
	}

	heartbeat() {
		this.heartTimer && clearTimeout(this.heartTimer)
		this.heartTimer = setTimeout(() => {
			this.send({
				event: "woker:ping"
			});
			this.heartbeat()
		}, 5000)
	}

	subscribe(uid) {
		this.uid = uid || this.uid
		// console.info('uid', this.uid)
		if (this.uid) {
			this.send({
				event: "woker:subscribe",
				data: {
					channel: `room-${this.uid}`
				}
			})
			return true
		}
		return false
	}

	subscribe_presence(uid) {
		console.info('subscribe_presence', uid, this.socket_id)
		this.presence_uid = uid || this.presence_uid
		if (this.socket_id) {
			if (this.presence_uid) {
				const sign = wsAppKey + ':' + md5(this.socket_id + this.presence_uid + wsAppSecret)
				this.send({
					event: "woker:subscribe",
					data: {
						sign,
						channel_data: this.presence_uid,
						channel: `presence-${this.presence_uid}`
					}
				});
				return true
			}
		} else {
			setTimeout(() => { this.subscribe_presence(this.presence_uid) }, 1000)
		}
		return false
	}
}

export default Connect
