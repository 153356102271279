<template>
	<div class="footer" :style="{marginTop: isAppe?'0':'40px'}" >
		{{  }}
		<div class="wrapper">
			<!-- 粤IPC 备 19005704号-1 | -->
			Copyight©2022 360吧.Rights Reserved |
			<router-link to="/aboutus" class="link">关于360吧</router-link>
			|
			<router-link to="/disclaimer" class="link">免责申明</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'wsFooter',
	computed: {
		isAppe() {
			return this.$route.path === '/app';
		},
	 }
};
</script>

<style lang="less"></style>
